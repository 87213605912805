import { Link } from "react-router-dom";
import "../styles/Ecosystem.scss";


const ecosystemArray = [
    {
        header: "BlockStar Casino",
        ribbion: "Casino",
        class: "casino-hover",
        span: "span-casino",
        description: 'Get ready to roll the dice and hit the jackpot with BlockStar’s Web3-powered casino, where you can enjoy cutting-edge games, and provably fair betting on a decentralized platform.',
        link: "https://blockstar.casino/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Poker",
        ribbion: "Poker",
        class: "poker-hover",
        span: "span-poker",
        description: `Feel the rush of the river and the thrill of the bluff in our fully decentralized poker room. BlockStar Poker is your ace in the hole for a secure gaming experience. Your seat at the blockchain’s finest felt awaits.${"   "}Ante up!`,
        link: "https://blockstarpoker.com/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Games",
        ribbion: "Games",
        class: "games-hover",
        span: "span-games",
        description: `Immerse yourself in a world of captivating games, from timeless classics to modern challenges. Enjoy endless entertainment without any downloads. Start playing now and discover your new gaming obsession!`,
        link: "/games",
        is_new: 1,
        link_type: 1
    }
];


export default function Gaming() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (
                                item.is_new === 1 ? (
                                    <Link
                                    to={item.link}
                                        className={item.class}
                                        key={index}>
                                        <div class="ribbon ribbon-top-left">
                                            <span className={item.span}>{item.ribbion}</span>
                                        </div>
                                        <span>{item.description}</span>
                                    </Link>

                                ) : (

                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={item.class}
                                        key={index}
                                    >
                                        <div class="ribbon ribbon-top-left">
                                            <span className={item.span}>{item.ribbion}</span>
                                        </div>
                                        <span>{item.description}</span>
                                    </a>
                                )



                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
